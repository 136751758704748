<template>
  <el-header height="auto" class="header">
    <div id="header">
      <div v-if="isMobile === false">
        <div class="centerWidth search-result-wrap">
          <div class="search-result-box">
            <div class="rightSearch">
              <div class="searchBox">
                <el-input
                  v-if="languageSelect === 'zh'"
                  class="searchContent"
                  v-model="seachInput"
                  placeholder="请输入搜索关键词"
                ></el-input>
                <el-input
                  v-if="languageSelect === 'en'"
                  class="searchContent"
                  v-model="seachInput"
                  placeholder="Please search keywords"
                ></el-input>
                <i class="el-icon-search" @click="Search"></i>
              </div>
            </div>
            <el-tabs v-model="tabActive" @tab-click="handleClick">
              <el-tab-pane :label="item.name" v-for="item in tabs" :name="item.primaryTypeid" :key="item.primaryTypeid"></el-tab-pane>
            </el-tabs>

            <div class="search-result-list" v-if="dataList.length">
              <div class="s-result-num">{{ $t('search.searchResultTip', { total: total }) }}</div>
              <template v-if="'1,4'.includes(tabActive)">
                <div class="s-result-item" v-for="item in dataList" :key="item.id" @click="toDetail(item.id)">
                  <div class="tecPhoto"><img :src="item[trans('img,imgEn')] ? baseURL + item[trans('img,imgEn')] : ''" alt="" /></div>
                  <div class="sri-txt">
                    <div class="tecTitle1 oneLine" v-html="item[trans('title,titleEn')]"></div>
                    <div class="tecTitle2 twoLine" v-html="item[trans('content,contentEn')]"></div>
                    <div class="sri-txt-bott">
                      <div>
                        <span class="tecTitle3">{{item.createTime}}</span>
                      </div>
                      <span><i class="el-icon-thumb"></i> {{item.koudsNum}}</span>
                    </div>
                  </div>
                </div>
              </template>
              <template v-if="tabActive == 3">
                <div class="tec2" v-for="item in dataList" :key="item.id" @click="toDetail(item.id)">
                  <div class="opportunity-img">
                    <img :src="item.img ? baseURL + item.img : ''" alt="" class="tecPhoto1" />
                    <div class="deadline-box" v-if="isDone(item.end_time)">
                      <div class="deadline-txt">{{$t('search.countdown1')}}<br><span>{{ hasDay(item.end_time) }}</span>{{$t('search.countdown2')}}</div>
                    </div>
                  </div>
                  <div style="width: 600px">
                    <div class="sjTitle1 oneLine" v-html="item.title"></div>
                    <div
                      style="
                        display: flex;
                        justify-content: space-between;
                        margin-top: 30px;">
                      <span>{{item.enterprise_name}}</span>
                      <span class="tecTitle3">{{item.createTime}}</span>
                    </div>
                  </div>
                </div>
              </template>
              <template v-if="tabActive == 2">
                <div class="mBox">
                  <div class="mGrand" v-for="item in dataList" :key="item.id" @click="toDetail(item.id)">
                    <div>
                      <img
                        :src="item.img ? baseURL + item.img : ''"
                        alt=""
                        class="mBg"
                      />
                    </div>
                    <div style="padding: 20px">
                      <p class="mT1 twoLine" v-html="item.title"></p>
                      <p class="mT2 threeLine" v-html="item.digest"></p>
                      <div
                        style="
                          display: flex;
                          justify-content: space-between;
                          margin-top: 9px;
                        "
                      >
                        <p class="mPage"><i class="el-icon-document"></i> {{item.page}} {{ $t('report.pages') }}</p>
                        <p class="mButton">{{ $t('report.viewReport') }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <div class="pagination-container">
                <el-pagination
                  hide-on-single-page
                  :pager-count="5"
                  layout="prev, pager, next"
                  :page-size="size"
                  @current-change="handleCurrentChange"
                  :total="total"
                  background>
                </el-pagination>
              </div>
            </div>
            <el-empty v-else :description="$t('common.noData')"></el-empty>
          </div>
          <Right :modules="rightModules"></Right>
        </div>

        <AiPop></AiPop>
      </div>
      <div v-else>
        <div class="centerWidth1 flex1">
          <div class="rightSearch1" style="display: flex">
            <!-- <el-page-header @back="goBack" style="margin-right: 5px">
            </el-page-header> -->
            <div class="searchBox1">
              <el-input
                v-if="languageSelect === 'zh'"
                class="searchContent"
                prefix-icon="el-icon-search"
                clearable
                v-model="seachInput"
                placeholder="请输入搜索关键词"
              ></el-input>
              <el-input
                v-if="languageSelect === 'en'"
                class="searchContent"
                prefix-icon="el-icon-search"
                clearable
                v-model="seachInput"
                placeholder="Please search keywords"
              ></el-input>
              <p class="searchTo" @click="Search">{{ $t('common.search') }}</p>
            </div>
          </div>
          <!-- <div style="margin-right: 15px">
            <el-popover placement="bottom" width="100" trigger="click">
              <div style="font-size: 14px; line-height: 2">
                <div style="display: flex">
                  <i
                    class="el-icon-message-solid"
                    style="color: gray; margin: auto 20px"
                  ></i
                  >消息
                </div>
                <el-divider></el-divider>
                <div>
                  <div
                    style="display: flex"
                    v-if="languageSelect === 'en'"
                    @click="changeLanguage('zh')"
                  >
                    <i
                      class="el-icon-mic"
                      style="color: gray; margin: auto 20px"
                    ></i
                    >中文
                  </div>
                  <div
                    style="display: flex"
                    v-if="languageSelect === 'zh'"
                    @click="changeLanguage('en')"
                  >
                    <i
                      class="el-icon-mic"
                      style="color: gray; margin: auto 20px"
                    ></i
                    >English
                  </div>
                </div>

                <el-divider></el-divider>
                <div>
                  <div
                    v-if="this.userId !== null"
                    style="display: flex"
                    @click="outLogin()"
                  >
                    <i
                      class="el-icon-switch-button"
                      style="color: gray; margin: auto 20px"
                    ></i
                    >退出
                  </div>
                  <div v-else style="display: flex">
                    <i
                      class="el-icon-loading"
                      style="color: gray; margin: auto 20px"
                    ></i>
                    <router-link class="login" to="/login">登录</router-link>
                  </div>
                </div>
              </div>
              <el-button
                slot="reference"
                icon="el-icon-circle-plus-outline"
                style="
                  border: none;
                  color: var(--main-color);
                  font-size: 25px;
                  width: 15px;
                  margin-right: 10px;
                "
              ></el-button>
            </el-popover>
          </div> -->
        </div>
        <div class="searchList">
          <p class="search-list-num">{{ $t('search.searchResultTip', { total: total }) }}</p>
          <div class="search-list-Box">
            <el-tabs v-model="tabActive" @tab-click="handleClick">
              <el-tab-pane :label="item.name" v-for="item in tabs" :name="item.primaryTypeid" :key="item.primaryTypeid"></el-tab-pane>
            </el-tabs>

            <div class="m-search-result-list" v-if="dataList.length" :style="tabActive == 3 ? 'background-color: #f5f5f5;' : ''">
              <template v-if="'1,4'.includes(tabActive)">
                <div class="m-info-box" v-for="item in dataList" :key="item.id" @click="toDetail(item.id)">
                  <div class="m-info-txt">
                    <p class="m-info-title twoLine"  v-html="item[trans('title,titleEn')]"></p>
                    <div class="m-info-time">
                      <span class="">{{item.createTime}}</span>
                    </div>
                  </div>
                  <img :src="item[trans('img,imgEn')] ? baseURL + item[trans('img,imgEn')] : ''" alt="" class="m-info-img" />
                </div>
              </template>

              <template v-if="tabActive == 3">
                <div class="sortBox" v-for="item in dataList" :key="item.id" @click="toDetail(item.id)">
                  <div class="sortBoxHeader">
                    <span v-if="badge(item.thirdName)">{{ badge(item.thirdName) }}</span>
                    <p class="oneLine" v-html="item.title"></p>
                  </div>
                  <div>
                    <div class="sortBoxT">
                      <p>{{ $t('opportunity.startTime') }}：<span>{{ item.start_time ? item.start_time.split(' ')[0] : '' }}</span></p>
                      <p>{{ $t('opportunity.endTime') }}：<span>{{ item.end_time ? item.end_time.split(' ')[0] : '' }}</span></p>
                    </div>
                    <div class="sortBoxT1">
                      <p>{{ $t('opportunity.enterpriseName') }}：<span>{{ item.enterprise_name }}</span></p>
                    </div>
                  </div>
                </div>
              </template>
              <template v-if="tabActive == 2">
                <div class="m-r-report-box">
                  <div class="newsGrand" v-for="item in dataList" :key="item.id" @click="toDetail(item.id)">
                    <div style="position: relative;cursor: pointer;">
                      <img :src="item.img ? baseURL + item.img : ''" alt="" class="newsBg" />
                      <!-- <p class="industryReportBT">11</p> -->
                    </div>
                    <div class="m-r-report-txt">
                      <p class="newsT1 twoLine" v-html="item.title"></p>
                      <div class="newsT2">
                        <span><i class="el-icon-document"></i> {{item.page}} {{ $t("report.pages") }}</span>
                        <span class="industryReportB">{{ $t('report.viewReport') }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <el-empty v-else :description="$t('common.noData')"></el-empty>
          </div>
          <!--        分页-->
          <Pagination v-if="dataList.length" :size="size" @current-change="handleCurrentChange" :total="total"></Pagination>
        </div>
        <AiPop></AiPop>
        <!-- <div class="centerWidth1 nav1" v-if="languageSelect === 'zh'">
          <router-link
            v-for="(item, index) in navList"
            :key="index"
            :to="item.url || ''"
            @click="setActive(index)"
            :class="[
              activeName == item.url ? 'active' : '',
              'item',
              item.url == '/Science?id=4' ? 'after' : '',
              item.url == '/mySubscriptions' ? 'after' : '',
            ]"
          >
            {{ item.name }}
          </router-link>
        </div>
        <div class="centerWidth1 nav1" v-if="languageSelect === 'en'">
          <router-link
            v-for="(item, index) in navList"
            :key="index"
            :to="item.url || ''"
            @click="setActive(index)"
            :class="[
              activeName == item.url ? 'active' : '',
              'item',
              item.url == '/AIAnswer' ? 'after' : '',
              item.url == '/mySubscriptions' ? 'after' : '',
            ]"
          >
            {{ item.nameEn }}
          </router-link>
        </div> -->
      </div>
    </div>
    <LoginTip
      :dialogVisible="dialogVisible"
      @hideDialog="hideDialog"
    ></LoginTip>
  </el-header>
</template>
<script>
import { logOut, userDetail } from "@/api/user";
import { navList, getNews, getReports, getNewsEn } from "@/api/index";
import Right from "@/components/Right.vue";
import LoginTip from "@/components/LoginTip.vue";
import mixins from "@/utils/mixins.js";
import { mapState } from 'vuex'
import Pagination from "@/components/mobile/Pagination.vue";
import AiPop from "@/components/AiPop.vue";

export default {
  components: {
    Right,LoginTip,Pagination, AiPop
  },
  mixins: [mixins],
  data() {
    return {
      currentPage: 1,
      totalPages: 20,
      activeName1: "first",
      activeName: "first",
      options: [
        { value: "1", label: "新建对话", iconClass: "el-icon-chat-dot-round" },
        {
          value: "2",
          label: "历史记录",
          iconClass: "el-icon-document-checked",
        },
        // Add more options as needed
      ],
      selectedOption: null,
      activeIndex: null,
      isMobile: false,
      languageSelect: "",
      // baseURL: this.$utils.baseURL,
      // userInfo: this.$store.state.user,
      userId: "",
      avator: require("@/assets/avator.png"),
      
      navList: this.$store.state.navList,
      count: this.$store.state.MessageCount,
      ThremeCount: 0,
      LikeCount: 0,
      MyCount: 0,
      allCount: this.cou3t,
      primaryTypeid: null,
      navLinks: {
        zh: {
          text: "关于我们", // 中文导航文本
          link: "/language=zh", // 中文导航链接
        },
        en: {
          text: "About Us", // 英文导航文本
          link: "/language=en", // 英文导航链接
        },
      },

      page: 1,
      size: 30,
      total: 0,
      tabActive: '1',
      dataList: [],
      tabs: [
        {
          primaryTypeid: '1',
          name: this.$t('index.technology'),
          route: 'technology'
        },
        {
          primaryTypeid: '4',
          name: this.$t('index.news'),
          route: 'news'
        },
        {
          primaryTypeid: '3',
          name: this.$t('index.opportunity'),
          route: 'opportunity'
        },
        {
          primaryTypeid: '2',
          name: this.$t('index.report'),
          route: 'report'
        }
      ],
      rightModules: [
        {
          type: "news",
          path:'news',
          title: this.$t('right.latestInfo'),
          dataNum: 5,
          primaryTypeId: 4,
        },
        {
          type: "report",
          path: 'report',
          title: this.$t('right.latestRJ'),
          dataNum: 5,
          primaryTypeId: 2,
        },
      ],
      seachInput: "",
      dialogVisible: false,
    };
  },
  computed: {
    ...mapState({
      'access_token': 'access_token', 
      'userInfo': 'user'
    })
  },
  // beforeRouteUpdate(to, from, next) {
  //   console.log(to.path !== from.path)
  //   if (to.path !== from.path) {
  //     next();
  //   }else{
  //     next(false);
  //   }
  // },
  // watch: {
  //   "$store.state.user"(newValue) {
  //     this.userInfo = newValue;
  //   },
  //   "$store.state.MessageCount"(newValue) {
  //     this.count = newValue;
  //   },
  // },
  async created() {
    // this.getNavList();
    // if (this.$store.state.access_token) {
    //   this.getUser();
    // }

    this.userId = localStorage.getItem("userId");
    console.log("---------");
    console.log(this.userId);
    if (localStorage.getItem("language")) {
      this.languageSelect = localStorage.getItem("language");
    } else {
      this.languageSelect = "zh";
    }

    this.seachInput = this.$route.params.keyword
    this.setTKD()
    await this.getNews()
  },
  beforeDestroy() {
    // 组件销毁前移除事件监听器
    document.removeEventListener("keydown", this.onKeyDown);
  },
  methods: {
    async getNews(){
      let fn = this.tabActive == 2 ? getReports : this.$i18n.locale == 'en' ? getNewsEn : getNews;
      let res = await fn({
        title: this.seachInput,
        primaryTypeid: this.tabActive,
        pageNum: this.page,
        pageSize: this.size,
        isSort: 2,
        draftStatus: 0,
        articleFlag: 1,
      });
      this.dataList = res.rows.map(item => {
        item.content = this.wordHighlight(this.setText(item.content || '').substr(0, 150))
        item.contentEn = this.wordHighlight(this.setText(item.contentEn || '').substr(0, 150))

        item.title = this.wordHighlight(item.title || '')
        item.titleEn = this.wordHighlight(item.titleEn || '')

        item.digest = this.wordHighlight(item.digest || '')
        return item
      })
      this.total = res.total
    },
    handleClick(){
      this.page = 1;
      this.getNews();
    },
    wordHighlight(html){
      return html.replace(
        new RegExp(this.seachInput, "gi"),
        (match) =>
          `<span style="color: #ff9500;">${match}</span>`
      );
    },
    toDetail(id){
      let type = this.tabs.find(item => item.primaryTypeid === this.tabActive).route

      if('news technology report'.includes(type)){
        this.$router.push(`/${type}/` + id);
      }else{
        if (this.userInfo.userId) {
          this.$router.push(`/${type}/` + id);
        } else {
          this.dialogVisible = true;
        }
      }
    },
    handleCurrentChange(val) {
      this.page = val;
      this.handleScroll();
      this.getNews();
    },
    handleScroll() {
      // 在这里阻止默认的滚动行为，或者自定义滚动行为
      window.scrollTo(0, 0); // 例如滚动到顶部
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    isDone(time){
      if(time){
        return new Date(time) >= new Date();
      }
      return false
    },
    hasDay(time){
      if(time){
        return Math.ceil((new Date(time).getTime() - new Date().getTime()) / (1000 * 3600 * 24));
      }
      return 0
    },
    // 搜索
    Search() {
      sessionStorage.setItem("keywords", this.seachInput);
      this.$emit("keywords", { seachInput: this.seachInput, searchChange: 1 });

      // 设置搜索关键词的长度 
      let keywords = this.seachInput && this.seachInput.substr(0, 20);
      if(keywords == this.$route.params.keyword){
        // this.$router.replace(`/search/${ encodeURIComponent(keywords) }`);
        return false
      }
      this.$router.push(`/search/${ encodeURIComponent(keywords) }`);
    },
    onKeyDown(event) {
      if (event.keyCode === 13) {
        // 如果按下的是回车键，执行搜索功能
        this.Search();
      }
    },
    setTKD(){
      let title = this.$t(`search.navTitle`, { title: this.seachInput });
      let keywords = this.$t(`search.navKeywords`, { keyword: this.seachInput });
      let description = this.$t(`search.navDescription`, { keyword: this.seachInput });


      document.title = title;
      document.querySelector('meta[property="og:title"]').content = title;
      document.querySelector('meta[property="og:keywords"]').content = keywords
      document.querySelector('meta[property="og:description"]').content = description
    },
    badge(data){
      return data ? (data.includes("招标")
      ? this.$t('opportunity.tender')
      : data.includes("公示")
      ? this.$t('opportunity.rAnnouncement')
      : "") : '';
    },

    goToPreviousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    goToNextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    showPopover() {
      this.$refs.customPopover.doToggle(); // 打开弹出框
    },
    selectOption(item) {
      this.selectedOption = item;
      this.$refs.customPopover.doClose(); // 选中选项后关闭弹出框
      // 根据需要执行其他操作
    },
    setActive(index) {
      this.activeIndex = index;

      // 确保导航数据已加载
      this.$nextTick(() => {
        // 获取导航项的父容器
        const navWrapper = document.querySelector(".nav1");
        if (navWrapper) {
          // 获取被点击项的位置（假设每个导航项宽度相同）
          const itemWidth = navWrapper.children[0].offsetWidth;

          // 将被点击项移动到中心位置
          navWrapper.scrollTo({
            left: itemWidth * index - (navWrapper.offsetWidth - itemWidth) / 2,
            behavior: "smooth",
          });
        }
      });
    },
    changeLanguage(language) {
      localStorage.setItem("language", language);
      this.languageSelect = localStorage.getItem("language");
      // 获取当前路由信息
      const currentRoute = this.$router.currentRoute;

      // 获取当前路由的查询参数
      const query = { ...currentRoute.query };

      // 更新语言参数为新的语言
      query.language = language;

      // 使用 $router.replace 更新路由，并传入新的查询参数
      this.$router.replace({ query }).catch((err) => {
        if (err.name !== "NavigationDuplicated") {
          throw err;
        }
      });
      window.location.reload();
    },
    getUser() {
      userDetail().then((res) => {
        this.userInfo = res.data;
        localStorage.setItem("userInfo", JSON.stringify(this.userInfo));
        this.$store.state.user = res.data;
        this.noticeCount();
      });
    },
    noticeCount() {
      this.noticeCountCom();
      setInterval(() => {
        this.noticeCountCom();
      }, 1000 * 60);
    },
    getNavList() {
      navList().then((res) => {
        this.navList = res.data;
        this.$store.state.navList = res.data;
        this.navList.forEach((item) => {
          var str1 = item.url.substring(0, item.url.indexOf("="));
          var str2 = item.url.substring(str1.length + 1, item.url.length);
          if (this.primaryTypeid == str2) {
            // this.activeName = item.url;
          }
        });
      });
    },
    
    // 退出登录
    outLogin() {
      logOut().then(() => {
        localStorage.removeItem("userInfo");
        localStorage.removeItem("access_token");
        localStorage.removeItem("userId");
        localStorage.removeItem("typeInfo");
        this.$store.state.access_token = "";
        this.$store.state.user = {};
        this.$message({
          message: "退出登录成功！", //提示的信息
          type: "success", //类型是成功
          duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
          onClose: () => {
            //跳转页面或执行方法
            location.reload();
          },
        });
      });
    },
  },
  
  mounted() {
    document.addEventListener("keydown", this.onKeyDown);
    // Check if the device width is less than 768px (example for mobile)
    this.isMobile = window.matchMedia("(max-width: 767px)").matches;
  },
};
</script>
<style lang="scss" scoped>
#header {
  background: #fff;
  // position: relative;

  .gery {
    background: #f7f7f7;
    border-bottom: 1px solid #eeeeee;
    height: 46px;

    .centerWidth {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 46px;

      .welcome {
        font-size: 14px;
        color: #666666;
      }

      .right {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        > div {
          margin-left: 32px;
        }

        .message {
          .el-dropdown {
            .el-icon-message-solid {
              font-size: 20px;
            }

            .el-badge__content {
              border: none;
            }

            .el-dropdown-menu__item {
              margin-bottom: 10px;
            }

            .el-dropdown-link {
              cursor: pointer;
            }

            .el-icon-arrow-down {
              font-size: 14px;
              color: #666666;
              line-height: 40px;
            }

            .demonstration {
              display: block;
              color: #8492a6;
              font-size: 14px;
              margin-bottom: 20px;
            }
          }
        }

        .userInfo {
          .el-dropdown-link {
            height: 100%;
            display: flex;
            align-items: center;
            cursor: pointer;

            .el-avatar {
              margin-right: 10px;
            }
          }
        }

        .login,
        .register,
        .forgetPassword {
          color: #666666;
          font-size: 14px;
          margin: 0 18px;
          position: relative;
        }

        .login:hover,
        .register:hover,
        .forgetPassword:hover {
          color: var(--main-color);
        }

        .login::after,
        .register::after {
          content: " ";
          background: #666666;
          width: 1px;
          height: 12px;
          position: absolute;
          right: -18px;
          top: 0;
          bottom: 0;
          margin: auto 0;
        }

        .chooseLanguage {
          color: #666666;
          border-color: #999999;
          background-color: inherit;
          height: 30px;
          line-height: 30px;
          padding-top: 0;
          padding-bottom: 0;
          font-size: 14px;
        }

        .chooseLanguage:focus,
        .chooseLanguage:hover {
          color: #666666;
          border-color: #999999;
          background-color: inherit;
        }
      }
    }
  }

  .search-result-wrap{
    display: flex;
    padding: 30px 0;
    justify-content: space-between;
    .search-result-box{
      width: 820px;
    }
    .rightSearch{
      margin-bottom: 30px;
      .searchBox {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border: 1px solid #f3f3f3;
        background-color: #f3f3f3;
        border-radius: 20px;
        overflow: hidden;
        padding-right: 20px;
        .searchContent {
          border-radius: 0;
          height: 40px;

          ::v-deep .el-input__inner {
            height: 100%;
            padding: 0 30px;
            background-color: transparent;
          }

          ::v-deep .el-input__inner::placeholder {
            color: #999;
          }
        }
        .el-icon-search{
          font-size: 26px;
          color: #aaa;
          cursor: pointer;
        }
      }
    }
    .search-result-list{
      .s-result-num{
        width: 100%;
        height: 40px;
        line-height: 40px;
        padding-left: 30px;
        font-size: 14px;
        background-color: #f8f8f8;
        margin: 10px 0 20px;
      }
    }

    .s-result-item {
      display: flex;
      padding: 16px 10px 16px 0;
      cursor: pointer;
      &:hover{
        background-color: #f8f8f8;
      }
      .tecTitle1:hover{
        color: var(--main-color);
      }
      .sri-txt{
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: space-between;
        overflow: hidden;
        .sri-txt-bott{
          display: flex;
          align-items: center;
          justify-content: space-between;
          & >span{
            color: #999;
          }
        }
      }
      
    }

    .opportunity-img{
      position: relative;
      margin-right: 20px;
      
      .deadline-box{
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        color: #fff;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, .4);
        // opacity: 0;
        // transition: opacity .5s;
        span{
          display: inline-block;
          margin: 6px 4px 0 0;
          padding: 2px 6px;
          background-color: #000;
          color: #fff;
        }
        // &:hover{
        //   opacity: 1;
        // }
      }
    }
    .mT1:hover{
      color: var(--main-color);
    }
  }

  .centerWidth.flex {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    width: 1160px;
    margin: 0 auto;
    margin-top: 10px;
    padding-top: 20px;
    padding-bottom: 16px;

    .logo {
      width: 185px;
      height: auto;
    }

    .rightSearch {
      width: 820px;
      // flex: 1;
      // margin-left: 80px;

      .searchBox {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border: 1px solid var(--main-color);

        .searchContent {
          border-radius: 0;
          height: 54px;

          ::v-deep .el-input__inner {
            height: 100%;
          }

          ::v-deep .el-input__inner::placeholder {
            color: #999;
          }
        }

        .searchTo {
          width: 96px;
          height: 54px;
          border: none;
          background: var(--main-color);
          border-radius: 0;

          img {
            width: 21px;
            height: 21px;
          }
        }
      }
    }
  }

  .centerWidth1.flex1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    height: 55px;
    padding-bottom: 8px;

    .logo1 {
      width: 30%;
      margin-left: 2%;
      height: auto;
    }

    .el-button {
      padding: 1px 2px;
    }

    .rightSearch1 {
      flex: 1;
      margin: 10px 2px;

      ::v-deep .el-page-header__left {
        margin: 5px !important;
        color: gray;
      }

      .searchBox1 {
        position: relative;
        width: 100%;
        margin-left: 4%;
        height: 37px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        // background: #f5f5f5;
        // border: 1px solid var(--main-color);

        .searchContent {
          border-radius: 0;
          color: #999999;
          height: 35px;

          ::v-deep .el-input__inner {
            // height: 100%;
            background: #f5f5f5;
            // background: url(https://imgs.ebrun.com/wapsite/images/search.png)
            //   12px 12px no-repeat #f5f5f5;
            // background-size: 10px 10px;
            // text-indent: 20px;
            border-radius: 20px;
          }

          ::v-deep .el-input__inner::placeholder {
            color: #999;
          }
        }
        // .searchDel {
        //   position: absolute;
        //   top: 5px;
        //   right: 95px;
        //   width: 20px;
        //   height: 20px;
        //   background: url(https://imgs.ebrun.com/wapsite/images/delete.png)
        //     center 5px no-repeat;
        //   background-size: 15px 15px;
        // }
        .searchTo {
          width: 96px;
          height: 35px;
          border: none;
          line-height: 35px;
          text-align: center;
          font-size: 16px;
          // background: var(--main-color);
          border-radius: 0;
          margin-left: 10px;

          img {
            width: 21px;
            height: 21px;
          }
        }
      }
    }
  }

  .nav {
    display: flex;
    align-items: center;
    height: 48px;
    line-height: 48px;

    > .item {
      flex: 1;
      text-align: center;
    }

    .active {
      color: var(--main-color);
      position: relative;
    }

    .active::after {
      content: "";
      width: 100%;
      height: 3px;
      position: absolute;
      bottom: 0;
      left: 0;
      background: var(--main-color);
    }

    .after {
      position: relative;
    }

    .after::before {
      content: "";
      width: 1px;
      height: 16px;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto 0;
      background: #999999;
    }
  }

  .nav1 {
    display: flex;
    align-items: center;
    height: 28px;
    line-height: 15px;
    font-size: 14px;
    overflow-x: auto; /* 开启水平滚动 */
    white-space: nowrap; /* 防止导航项换行 */
    &::-webkit-scrollbar {
      display: none; /* 显示滚动条 */
    }

    > .item {
      flex: 1;
      margin: 0 5px; /* 添加间距 */
      text-align: center;
    }

    .active {
      color: var(--main-color);
      position: relative;
    }

    .active::after {
      content: "";
      width: 100%;
      height: 3px;
      position: absolute;
      bottom: -5px;

      margin-top: 5px;
      left: 0;
      background: var(--main-color);
    }

    .after {
      position: relative;
      margin-right: 5px;
    }

    .after::before {
      content: "";
      width: 1px;
      height: 10px;
      position: absolute;
      left: -5px;
      top: 1px;
      background: #999999;
    }
  }
}

.flex {
  // display: flex;
  align-items: center;

  img.icon {
    width: 14px;
    height: 14px;
    margin-right: 5px;
  }

  .icon.hover {
    display: none;
  }
}

.hoverItem:hover {
  .icon.hover {
    display: inline-block;
  }

  .icon.not {
    display: none;
  }
}

::v-deep.el-header {
  padding: 0;
}

::v-deep .el-popover .el-popper {
  min-width: 100px !important;
}

::v-deep.el-dropdown-menu__item:focus,
::v-deep.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #fff;
  color: var(--main-color);
}

::v-deep.el-divider--horizontal {
  display: block;
  height: 1px;
  width: 100%;
  margin: 2px 0;
}

.userIN:not(.is-disabled):hover {
  background: none;
  color: #666;
}

.el-link.el-link--default:hover {
  color: var(--main-color);
}



.tecPhoto {
  width: 190px;
  height: 127px;
  overflow: hidden;
  margin-right: 20px;
}

.tecTitle1 {
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  color: #333;
}

.tecTitle2 {
  height: 52px;
  margin-top: 6px;
  overflow: hidden;
  font-size: 16px;
  line-height: 26px;
  color: #595959;
}

.tecTitle3 {
  font-size: 14px;
  line-height: 24px;
  color: #999;
  margin-right: 20px;
}

// .tecTitle4 {
//   padding-left: 17px;
//   background: url(https://imgs.ebrun.com/master/images/ico_pindao_xinwen_tag.png?__sprite)
//     no-repeat left center;
// }

::v-deep .el-tabs__item.is-active {
  color: #1a79ff;
}

::v-deep .el-tabs__item {
  font-weight: 700;
  font-size: 22px;
}

::v-deep .el-tabs__active-bar {
  // background-color: #fff !important;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #409eff !important;
}

::v-deep .el-pagination.is-background .el-pager li:hover {
  color: #409eff !important;
  border: 1px solid #409eff !important;
}

.paging {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.tecTitle5 {
  padding-left: 25px;
  background: url(//imgs.ebrun.com/master/images/ico_pindao_zan.png?__sprite)
    no-repeat left center;
}

.tecPhoto1 {
  width: 100px;
  height: 100px;
  overflow: hidden;
  // margin-right: 20px;
}

.tec2 {
  display: flex;
  margin-bottom: 10px;
  border: 1px solid rgb(232, 229, 229);
  padding: 20px;
}

.sjTitle1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 20px; /* 设置一个固定高度 */
  width: 220px;
  font-size: 14px;
  margin-top: 10px;
}

.mBox {
  display: flex;
  flex-wrap: wrap;
  margin-left: -12px;
}

.mGrand {
  width: 265px;
  height: 402px;
  margin-left: 12px;
  margin-bottom: 12px;
  overflow: hidden;
  // margin-top: 10px;
  // margin-right: 8px;
  background: #f2f7ff;
  border-radius: 2px;
}

.mBg {
  display: block;
  width: 100%;
  height: 184px;
  margin-top: 20px;
  overflow: hidden;
  object-fit: contain;
}

.mT1 {
  height: 48px;
  overflow: hidden;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #262626;
  transition: all 0.3s;
  cursor: pointer;
}

.mT2 {
  margin-top: 10px;
  height: 66px;
  overflow: hidden;
  font-size: 14px;
  color: #8a9099;
  line-height: 22px;
}

.mPage {
  font-size: 14px;
  color: #a1a8b3;
  line-height: 30px;
  // background: url(https://imgs.ebrun.com/master/2021/images/ico_report_page.png)
  //   no-repeat 0;
  // background-size: 13px 16px;
  // padding-left: 17px;
}

.mButton {
  width: 90px;
  height: 30px;
  background: #1a79ff;
  border-radius: 2px;
  line-height: 30px;
  font-size: 14px;
  color: #fff;
  text-align: center;
}

.searchList {
  //  padding: 15px 20px;
  min-height: 80vh;
}

.search-list-num {
  height: 40px;
  background-color: #f8f8f8;
  line-height: 40px;
  font-size: 14px;
  color: #666;
  text-indent: 20px;
}

.search-list-Box {
  // padding: 15px;
  ::v-deep .el-tabs{
    margin-top: 10px;
    .el-tabs__item {
      font-size: 14px;
      padding-left: 14px;
    }
    .el-tabs__nav-wrap::after {
      background-color: #fff;
    }
    .el-tabs__header{
      margin-bottom: 0;
      .el-tabs--top .el-tabs__item.is-top:nth-child(2){
        padding-left: 0;
      }
    }
  }
  .m-search-result-list{
    padding: 0 20px 20px;
    overflow: hidden;
  }
}

.search-list-tec {
  display: flex;
  justify-content: space-between;
  // align-items: center;
  padding: 0 20px;
}

.search-list-t1 {
  width: 180px;
  font-size: 16px;
  line-height: 20px;
  overflow: hidden; //超出的文本隐藏
  text-overflow: ellipsis; //用省略号显示
  white-space: nowrap; //不换行（文字不允许换行，单行文本）
  color: #17181a;
  font-weight: bold;
}

.search-list-t2 {
  display: block;
  margin-top: 40px;
  font-size: 12px;
  line-height: 20px;
  color: #a1a8b3;
}

.sortBox {
  display: block;
  height: 140px;
  background: #fff url('../assets/list-bj.png') no-repeat;
  background-size: 100% 100%;
  border-radius: 0.1rem;
  padding: 10px;
  margin-top: 20px;
}

.sortBoxHeader {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
  span {
    display: block;
    margin-right: 8px;
    font-size: 10px;
    color: #fff;
    padding: 2px 6px;
    border-radius: 14px;
    background-color: var(--main-bg-color);
  }
  p{
    flex: 1;
  }
}

.sortBoxT {
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    font-size: 12px;
    // display: flex;
    width: 49%;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background: #f7f8f9;
    border-radius: 0.1rem;
    text-align: left;
    margin-bottom: 10px;
    padding-left: 10px;
  }
}

.sortBoxT1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    font-size: 12px;
    display: flex;
    width: 100%;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background: #f7f8f9;
    border-radius: 0.1rem;
    text-align: left;
    margin-bottom: 10px;
    padding-left: 10px;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  span {
    border: 1px solid;
    padding: 5px 20px;
  }
  button {
    margin: 0 10px;
    padding: 5px 20px;
    background: #fff;
  }
}

.search-list-rep {
  width: 46%;
  margin-bottom: 10px;
  img {
    width: 100%;
    height: auto;
  }
  .search-list-repT1 {
    width: 100%;
    font-size: 16px;
    color: #17181a;
    text-align: left;
    line-height: 24px;
    font-weight: bold;
    height: 50px;
    overflow: hidden; //超出盒子宽度内容，便溢出隐藏
    text-overflow: ellipsis; //用...省略号显示
    display: -webkit-box; //将对象作为弹性伸缩盒子模型显示
    -webkit-box-orient: vertical; //从上到下垂直排列子元素
    -webkit-line-clamp: 2; //显示的行数
  }
  .search-list-repT2 {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAA0CAYAAAD8H6qwAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAKqADAAQAAAABAAAANAAAAAALyozoAAAB2UlEQVRoBe2ZvU7DMBDHzykb37DCOyBAIIFYkSisiEpUYuOh2KCtVFrGNm1VJiYehDdAbLUPuzSRZRpiJ8F1wVnqO1v2r/87XxKH1NvhGWN4h4hbYPEiQD5KBMrXV+cvOssGs4AUYAi4SBHCWrt/ogVqW0kZSsAiG4X1x/BY9k9rB9OcNn2IsESB9WrtztFP65L7ZgflATeVCyLbRbfV9eL5CbyXMDitVsqvsU9qzFzRmAVhmQEOHp66h7FPargDyqF4zq4AhUGj1T+QGMdNp0AFEd/cqyMcCWX3ZVjnQMdwCGtIcdhodfYiWDdBBR2HpQyGtWZ/V5jugo5ZYZ0RypXt7iwIWqcvxA0K8GxdUULIm6kw/KawaR00CMhtFljroa9elntc0e00VdU7mHVF0wCT+j1okjJZ/cY5mveNQGwksaEmuarNbRz6vG8E4kFdzKFNOBloDGq6QFHjjUGz1sEIOAp9ZOv+Gueobh3UBdAdZ6yo7sRFj/OgRStqnKO+jqaE4O/mqK+j/zb0KX/817rnZjP5OpqUA/55VFXG11FVEcWem13vQZXI5Ta9orklVCbwiiqC5Da9orklVCb49i1U6XfGnJ/QZzn4ty3z18Faxq8UtmCj079Pe7/MwOy4YJoAAAAASUVORK5CYII=)
      no-repeat left 4px;
    background-size: 10px 12px;
    font-size: 12px;
    color: #a1a8b3;
    line-height: 20px;
    padding-left: 16px;
  }
  .search-list-repT3 {
    width: 60px;
    height: 20px;
    background: #1a79ff;
    border-radius: 0.06rem;
    font-size: 14px;
    color: #fff;
    text-align: center;
    line-height: 20px;
  }
}


.pagination-container {
  position: sticky;
  margin-top: auto;
  bottom: 0;
  width: 99%;
  text-align: center;
  background-color: #fff;
  padding: 10px;
  margin: 20px 0;
}

.m-info-box{
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  .m-info-txt{
    flex: 1;
    display: flex;
    margin-right: 14px;
    flex-direction: column;
    justify-content: space-between;
    .m-info-title{
      font-size: 14px;
      font-weight: bold;
      line-height: 20px;
      cursor: pointer;
      &:hover{
        color: var(--main-color);
      }
    }
    .m-info-time {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 6px;
      font-size: 12px;
      line-height: 18px;
      color: #a1a8b3;
    }
  }
  .m-info-img{
    width: 120px;
    height: 70px;
  }

}

.newsT1 {
  // height: 2.7em;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  &:hover{
    color: var(--main-color);
  }
}
.newsT2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 6px;
  font-size: 12px;
  line-height: 18px;
  color: #a1a8b3;
}
.industryReportB {
  display: block;
  width: 56px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  background: #1a79ff;
  border-radius: 2px;
  font-size: 10px;
  color: #fff;
  cursor: pointer;
}

.m-r-report-box{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .newsGrand{
    display: flex;
    
    width: 45%;
    height: 220px;
    flex-direction: column;
    background-color: #f5f9ff;
    border-radius: 4px;
    justify-content: initial;
    margin-top: 20px;
    .newsBg{
      width: 100%;
      height: 110px;
      margin-top: 10px;
      vertical-align: top;
      object-fit: contain;
    }
    .m-r-report-txt{
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;
      padding: 8px;
    }
    .newsT2{
      margin-top: 10px;
    }
  }
}
</style>
