<template>
  <div>
    <Header></Header>
    <!--  @keywords="handleInfoFromHeader" -->
    <HeaderSearch></HeaderSearch>
    <ScrollTop v-if="isMobile === true"></ScrollTop>
    <!-- <Right></Right> -->
    <LoginTip
      :dialogVisible="dialogVisible"
      @hideDialog="hideDialog"
    ></LoginTip>
    <Footer v-if="isMobile === false"></Footer>
    <Bottom v-if="isMobile === true">123</Bottom>
  </div>
</template>


<script>
import HeaderSearch from "@/components/HeaderSearch.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
// import Right from "@/components/Right.vue";
import md5 from "js-md5";
import LoginTip from "@/components/LoginTip.vue";
// import BottomBanner from "@/components/BottomBanner.vue";
import { shareUrl } from "@/utils/vxshare.js";
import ScrollTop from "@/components/mobile/ScrollTop.vue";
import Bottom from "@/components/mobile/Bottom.vue";

export default {
  name: "",

  props: [],
  components: {
    ScrollTop,
    Bottom,
    LoginTip,
    HeaderSearch,
    Header,
    Footer,
    // Right,
  },
  data() {
    return {
      isMobile: false,
      dialogVisible: false,
      newsId: "",
      primaries: [],
      selectedColors: {},
      selectedPrimary: [],
      searchChange: 0,
      maxTextLength: 125, // 设置最大字数
      searchKeywords: "",
      seconderyClassfy: "",
      primaryTypeid: "",
      primaryTypeids: [],
      secondaryTypeid: "",
      secondaryTypeids: "",
      isLoading: "",
      articles: [],
      hovered: false,
      content: [],
      size: 8,
      page: 1,
      total: 1,
      change: 0,
      trans: false,
      collect: 0,
      translatedetaiContent: "",
      detailTitle: "",
      detailTime: "",
      detailContent: "",
      translatedetailTitle: "",
      zhStr: "",
      enStr: "",
      salt: parseInt(Math.random() * 1000000000), //随机数
      appid: "20231016001849033",
      userkey: "ZgPv1FfEEWXPsNH0HzWl",
      token: "",
      userId: "",
      languageSelect: "",
      titleEn: "",
      contentEn: "",
      isView: "",
      isWhole: "",
    };
  },
  created() {
    if (localStorage.getItem("language")) {
      this.languageSelect = localStorage.getItem("language");
    } else {
      this.languageSelect = "zh";
    }
    window.scrollTo(0, 0);
    //   获取新闻二级分类列表
    this.token = localStorage.getItem("access_token");
    this.userId = localStorage.getItem("userId");

    const currentPage = localStorage.getItem("setPage");
    if (currentPage) {
      try {
        const parsedPage = JSON.parse(currentPage);
        if (parsedPage.isFlag === "1") {
          this.page = parseInt(parsedPage.setPage);
          localStorage.setItem("setPage", "");
          localStorage.setItem("Title", "");
        } else {
          localStorage.setItem("Title", "");
          localStorage.setItem("setPage", ""); // 清空 localStorage
          this.page = 1;
        }
      } catch (error) {
        localStorage.setItem("Title", "");
        console.error("Error parsing JSON:", error);
        // 处理 JSON 解析错误
        this.page = 1; // 或者其他处理逻辑
      }
    } else {
      this.page = 1;
    }
    this.searchKeywords = this.$route.query.seachInput;
    //   获取新闻
    // if (this.languageSelect === "zh") {
    //   this.getartical();
    // } else {
    //   this.getarticalEn();
    // }

    // this.classfy();
    let url = window.location.href;
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        title: "油气知识",
        text: "洞悉油气行业变化，体验AI大模型，就来油气知识！",
      };
      shareUrl(shareData);
    }, 1000);
    // if (this.languageSelect === "en") {
    //   document.title = "Oil and Gas knowledge-" + "Search";
    // } else {
    //   document.title = "油气知识-" + "搜索";
    // }
  },
  mounted() {
    this.isMobile = window.matchMedia("(max-width: 767px)").matches;

    this.primaries.forEach((primary) => {
      this.selectedColors[primary.categoryId] = true;
    });
  },
  methods: {
    handleTripleClick() {
      console.log(this.primaryTypeid);
      this.page = 1;
      this.size = 8;
      this.primaryTypeids = this.primaryTypeid;
      if (this.primaryTypeid === "0") {
        this.primaryTypeids = "";
      }
      // if (this.languageSelect === "zh") {
      //   this.getartical();
      // } else {
      //   this.getarticalEn();
      // }
    },
    // 点赞
    agreeChange(artical) {
      console.log(typeof JSON.stringify(artical.id));
      if (this.user.userId) {
        if (artical.iskouds === 0) {
          this.$axios
            .post(
              "/api/kudos/addOrDelete",
              {
                newsId: JSON.stringify(artical.id),
                userId: this.userId,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: this.token,
                },
              }
            )
            .then((res) => {
              console.log(res);
              artical.iskouds = 1;
              artical.agree++;
            });
        } else {
          this.$axios
            .post(
              "/api/kudos/addOrDelete",
              {
                newsId: JSON.stringify(artical.id),
                userId: this.userId,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: this.token,
                },
              }
            )
            .then((res) => {
              console.log(res);
              artical.iskouds = 0;
              artical.agree--;
            });
        }
      } else {
        this.dialogVisible = true;
      }
    },
    showDialog(type) {
      this.dialogVisible = type;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    handlePrimary(primary) {
      if (this.selectedColors[primary.categoryId]) {
        for (const secondary of primary.secondaries) {
          this.selectedColors[secondary.categoryId] = true;
          this.secondaryTypeids += secondary.categoryId + ",";
        }
        if (!this.primaryTypeids.includes(primary.categoryId)) {
          this.primaryTypeids += primary.categoryId + ",";
        }
      } else {
        for (const secondary of primary.secondaries) {
          this.selectedColors[secondary.categoryId] = false;
          this.secondaryTypeids = this.secondaryTypeids.replace(
            secondary.categoryId + ",",
            ""
          );
        }
        this.primaryTypeids = this.primaryTypeids.replace(
          primary.categoryId + ",",
          ""
        ); // 移除一级分类的ID
      }
      this.page = 1;
      this.size = 8;
      // console.log(this.primaryTypeids);
      // if (this.languageSelect === "zh") {
      //   this.getartical();
      // } else {
      //   this.getarticalEn();
      // }
    },

    handleScondery(secondary) {
      if (this.selectedColors[secondary.categoryId]) {
        if (!this.secondaryTypeids.includes(secondary.categoryId)) {
          this.secondaryTypeids += secondary.categoryId + ",";
        }
      } else {
        this.secondaryTypeids = this.secondaryTypeids.replace(
          secondary.categoryId + ",",
          ""
        );
      }
      this.page = 1;
      this.size = 8;
      // console.log(this.secondaryTypeids);
      // if (this.languageSelect === "zh") {
      //   this.getartical();
      // } else {
      //   this.getarticalEn();
      // }
    },
    // 关键词
    handleInfoFromHeader(info) {
      this.searchKeywords = info.seachInput;
      this.searchChange = info.searchChange;
      this.page = 1;
      this.size = 8;
      // if (this.languageSelect === "zh") {
      //   this.getartical();
      // } else {
      //   this.getarticalEn();
      // }
      // if(this.languageSelect==="zh"){
      //   this.getartical()
      // }else{
      //   this.getarticalEn()
      // }
    },
    handleSearch(info) {
      this.searchChange = info;
      console.log(info);
    },
    //收藏
    collectChange() {
      if (this.collect === 1) {
        this.collect = 0;
      } else if (this.collect === 0) {
        this.collect = 1;
      }
    },
    // 翻译
    async translate() {
      /* 从页面获取选择的目标语言 传入url */
      this.to = "en";
      /* 对待翻译字符做url编码 */
      this.from = "zh";
      var titlesalt = Math.floor(Math.random() * 10000).toString();
      var transtitle = md5(
        this.appid + this.detailTitle + titlesalt + this.userkey
      );
      /* 请求翻译 */

      await this.$axios
        .get(
          "/baidu" +
            "?q=" +
            this.detailTitle +
            "&from=" +
            this.from +
            "&to=" +
            this.to +
            "&appid=" +
            this.appid +
            "&salt=" +
            titlesalt +
            "&sign=" +
            transtitle
        )
        .then((res) => {
          // console.log('标题')
          // console.log(res.data.trans_result[0].dst)
          this.translatedetailTitle = res.data.trans_result[0].dst;
        });
      const divElement = document.querySelector(".detail1");
      const textContent = divElement.innerText;
      console.log("内容" + textContent);
      var contentsalt = Math.floor(Math.random() * 10000).toString();
      var transcontent = md5(
        this.appid + this.detailContent + contentsalt + this.userkey
      );
      await this.$axios
        .get(
          "/baidu" +
            "?q=" +
            this.detailContent +
            "&from=" +
            this.from +
            "&to=" +
            this.to +
            "&appid=" +
            this.appid +
            "&salt=" +
            contentsalt +
            "&sign=" +
            transcontent
        )
        .then((res) => {
          // console.log(res.data.trans_result[0].dst)
          this.translatedetaiContent = res.data.trans_result[0].dst;
        });
    },
    // 二级分类
    sendSecondery(index) {
      // console.log('Clicked item at index:', index)
      this.searchKeywords = "";
      this.secondaryTypeid = index;
      this.page = 1;
      this.size = 8;
      // if (this.languageSelect === "zh") {
      //   this.getartical();
      // } else {
      //   this.getarticalEn();
      // }
    },
    // 获取二级分类
    classfy() {
      this.$axios
        .get("/api/category/optionSelect", {
          params: {
            level: 1,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: this.token,
          },
        })
        .then((res) => {
          this.primaries = res.data.data; // 将响应中的数据直接赋值给 primary
          // 遍历一级分类以获取二级分类
          this.primaries.forEach((primaryItem) => {
            this.$axios
              .get("/api/category/optionSelect", {
                params: {
                  level: 2,
                  parentId: primaryItem.categoryId,
                },
                headers: {
                  "Content-Type": "application/json",
                  Authorization: this.token,
                },
              })
              .then((response) => {
                // console.log(response.data);
                primaryItem.secondaries = response.data.data;
              })
              .catch((error) => {
                console.log(error);
              });
          });
          // console.log("shu ")
          // console.log(this.primary)
        })
        .catch((error) => {
          console.log(error);
        });
    },

    hoverIn() {
      this.hovered = true;
    },
    hoverOut() {
      this.hovered = false;
    },

    //   新闻
    async detailArtical(artical) {
      this.detailTitle = artical.title;
      this.detailContent = artical.content;
      this.q = artical.fanyi;
      this.detailTime = artical.createTime;
      this.newsId = artical.id;
      var vm = this;
      await vm.$axios
        .get("/api/category/optionSelect", {
          params: {
            level: 1,
            categoryId: artical.primaryTypeid,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: vm.token,
          },
        })
        .then((res) => {
          vm.isView = res.data.data[0].isView;
          this.isWhole = res.data.data[0].isWhole;
        })
        .catch((error) => {
          console.log(error);
        });
      // 不需要登录
      if (this.isView === "1") {
        this.$router.push({
          path: "/articalDetail",
          query: {
            currentPage: this.page,
            Typeid: artical.primaryTypeid,
            newId: artical.id,
          },
        });
      }
      // 需要登录
      else {
        // 已经登
        if (this.userId) {
          // 无需完善
          if (this.isWhole === "1") {
            this.$router.push({
              path: "/articalDetail",
              query: {
                currentPage: this.page,
                Typeid: artical.primaryTypeid,
                newId: artical.id,
              },
            });
          }
          // 需要完善
          else {
            if (this.$store.state.typeInfo == 0) {
              this.dialogVisibleInfo = true;
            } else {
              this.$router.push({
                path: "/articalDetail",
                query: {
                  currentPage: this.page,
                  Typeid: artical.primaryTypeid,
                  newId: artical.id,
                },
              });
            }
          }
        } else {
          this.dialogVisible = true;
        }
      }

      // console.log(this.newsId)
    },
    //获取是否登录可查看
    loginView() {},

    //   获取文章
    getartical() {
      if (this.languageSelect === "zh") {
        this.$axios
          .get("/api/news/listAllZhEn", {
            params: {
              primaryTypeids: this.primaryTypeids,
              secondaryTypeids: this.secondaryTypeids,
              title: this.searchKeywords,
              pageNum: this.page,
              pageSize: this.size,
              draftStatus: 0,
              articleFlag: 1,
            },
            headers: {
              Authorization: this.token,
            },
          })
          .then((res) => {
            console.log(res);
            window.scrollTo(0, 0);
            this.total = res.data.total;
            this.articles = res.data.rows; // 将响应中的数据直接赋值给 articles 对象数组
            // this.list();
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.$axios
          .get("/api/news/listAllEnZh", {
            params: {
              primaryTypeids: this.primaryTypeids,
              secondaryTypeids: this.secondaryTypeids,
              title: this.searchKeywords,
              pageNum: this.page,
              pageSize: this.size,
              draftStatus: 0,
              articleFlag: 1,
            },
            headers: {
              Authorization: this.token,
            },
          })
          .then((res) => {
            console.log(res);
            window.scrollTo(0, 0);
            this.total = res.data.total;
            this.articles = res.data.rows; // 将响应中的数据直接赋值给 articles 对象数组
            this.list();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    getarticalEn() {
      if (this.languageSelect === "zh") {
        this.$axios
          .get("/api/news/listAllZhEn", {
            params: {
              primaryTypeids: this.primaryTypeids,
              secondaryTypeids: this.secondaryTypeids,
              title: this.searchKeywords,
              pageNum: this.page,
              pageSize: this.size,
              draftStatus: 0,
              articleFlag: 1,
            },
            headers: {
              Authorization: this.token,
            },
          })
          .then((res) => {
            console.log(res);
            window.scrollTo(0, 0);
            this.total = res.data.total;
            this.articles = res.data.rows; // 将响应中的数据直接赋值给 articles 对象数组
            this.list();
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.$axios
          .get("/api/news/listAllEnZh", {
            params: {
              primaryTypeids: this.primaryTypeids,
              secondaryTypeids: this.secondaryTypeids,
              title: this.searchKeywords,
              pageNum: this.page,
              pageSize: this.size,
              draftStatus: 0,
              articleFlag: 1,
            },
            headers: {
              Authorization: this.token,
            },
          })
          .then((res) => {
            window.scrollTo(0, 0);
            this.total = res.data.total;
            this.articles = res.data.rows; // 将响应中的数据直接赋值给 articles 对象数组
            if (this.articles.length === 0) {
              this.isLoading = true;
            } else {
              this.isLoading = false;
              for (var i = 0; i < this.articles.length; i++) {
                this.content[i] = this.articles[i].content;
                const currentDate = new Date(this.articles[i].createTime);
                const year = currentDate.getFullYear();
                const month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
                const day = ("0" + currentDate.getDate()).slice(-2);
                const formattedDate = `${year}-${month}-${day}`;

                this.articles[i].titleEn = this.articles[i].title;
                this.articles[i].contentEn = this.articles[i].content;

                this.articles[i].fanyi = this.stripHtmlTags(
                  this.articles[i].content
                );
                if (this.articles[i].content.length > this.maxTextLength) {
                  const truncatedText =
                    this.articles[i].fanyi.slice(0, this.maxTextLength) + "…";
                  this.articles[i].truncatedContent = truncatedText; // 保存截断后的内容到新字段
                } else {
                  this.articles[i].truncatedContent = this.articles[i].fanyi; // 如果没有截断，保存原始内容
                }
                this.articles[i].leighttitle = this.articles[i].title.replace(
                  new RegExp(this.searchKeywords, "gi"),
                  (match) =>
                    `<span style="color: var(--main-color)">${match}</span>`
                );
                this.articles[i].titleEn = this.articles[i].titleEn.replace(
                  new RegExp(this.searchKeywords, "gi"),
                  (match) =>
                    `<span style="color: var(--main-color)">${match}</span>`
                );
                console.log(this.articles[i].titleEn);
                this.articles[i].imgChange = 0;
                if (this.articles[i].viewNum === null) {
                  this.articles[i].scan = 0;
                } else {
                  this.articles[i].scan = this.articles[i].viewNum;
                }
                if (this.articles[i].koudsNum === null) {
                  this.articles[i].agree = 0;
                } else {
                  this.articles[i].agree = this.articles[i].koudsNum;
                }
                this.articles[i].createTime = formattedDate;

                // 使用简单的字符串替换来处理src属性
                let newContent = this.content[i].replace(
                  /<img [^>]*src=['"]([^'"]+)[^>]*>/gi,
                  (match, p1) => {
                    let imgSrc =
                      p1.indexOf("http") > -1 ? p1 : this.$utils.baseURL + p1;
                    this.articles[i].imgChange = 1;
                    // console.log(this.articles[i].contentImg)
                    // 获取第一张图片
                    if (this.articles[i].contentImg === undefined) {
                      this.articles[
                        i
                      ].contentImg = `<img style="text-align: center;" src='${imgSrc}' />`;
                    }
                    return `<img style="text-align: center; height: 250px; width: 40%;background-size:contain;background-position:center;background-repeat:no-repeat;margin: 1% 30% 1% 30%;box-shadow: 5px 5px 5px #888888;" src="${imgSrc}"/>`;
                  }
                );
                this.articles[i].content = newContent;
                this.content[i] = newContent;
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    async list() {
      if (this.articles.length === 0) {
        this.isLoading = true;
      } else {
        this.isLoading = false;
        for (var i = 0; i < this.articles.length; i++) {
          this.content[i] = this.articles[i].content;
          const currentDate = new Date(this.articles[i].createTime);
          const year = currentDate.getFullYear();
          const month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
          const day = ("0" + currentDate.getDate()).slice(-2);
          const formattedDate = `${year}-${month}-${day}`;

          var vm = this;
          await vm.$axios
            .post("/api/enes/trans", {
              // params: {
              newsId: vm.articles[i].id,
              // },
              headers: {
                Authorization: vm.token,
              },
            })
            .then((res) => {
              // console.log(res.data.data.enTitle)
              vm.titleEn = res.data.data.enTitle;
              vm.contentEn = res.data.data.enContent;
            })
            .catch((error) => {
              console.log(error);
            });
          this.articles[i].titleEn = this.titleEn;
          this.articles[i].contentEn = this.contentEn;

          this.articles[i].fanyi = this.stripHtmlTags(this.articles[i].content);
          if (this.articles[i].content.length > this.maxTextLength) {
            const truncatedText =
              this.articles[i].fanyi.slice(0, this.maxTextLength) + "…";
            this.articles[i].truncatedContent = truncatedText; // 保存截断后的内容到新字段
          } else {
            this.articles[i].truncatedContent = this.articles[i].fanyi; // 如果没有截断，保存原始内容
          }
          this.articles[i].leighttitle = this.articles[i].title.replace(
            new RegExp(this.searchKeywords, "gi"),
            (match) => `<span style="color: var(--main-color)">${match}</span>`
          );
          this.articles[i].titleEn = this.articles[i].titleEn.replace(
            new RegExp(this.searchKeywords, "gi"),
            (match) => `<span style="color: var(--main-color)">${match}</span>`
          );
          this.articles[i].imgChange = 0;
          if (this.articles[i].viewNum === null) {
            this.articles[i].scan = 0;
          } else {
            this.articles[i].scan = this.articles[i].viewNum;
          }
          if (this.articles[i].koudsNum === null) {
            this.articles[i].agree = 0;
          } else {
            this.articles[i].agree = this.articles[i].koudsNum;
          }
          this.articles[i].createTime = formattedDate;

          // 使用简单的字符串替换来处理src属性
          let newContent = this.content[i].replace(
            /<img [^>]*src=['"]([^'"]+)[^>]*>/gi,
            (match, p1) => {
              let imgSrc =
                p1.indexOf("http") > -1 ? p1 : this.$utils.baseURL + p1;
              this.articles[i].imgChange = 1;
              // console.log(this.articles[i].contentImg)
              // 获取第一张图片
              if (this.articles[i].contentImg === undefined) {
                this.articles[
                  i
                ].contentImg = `<img style="text-align: center;" src='${imgSrc}' />`;
              }
              return `<img style="text-align: center; height: 250px; width: 40%;background-size:contain;background-position:center;background-repeat:no-repeat;margin: 1% 30% 1% 30%;box-shadow: 5px 5px 5px #888888;" src="${imgSrc}"/>`;
            }
          );
          this.articles[i].content = newContent;
          this.content[i] = newContent;
        }
      }
    },
    stripHtmlTags(html) {
      return html.replace(/<[^>]*>/g, ""); // 使用正则表达式去除HTML标记
    },
    // 分页
    handleScroll() {
      // 在这里阻止默认的滚动行为，或者自定义滚动行为
      window.scrollTo(0, 0); // 例如滚动到顶部
    },
    handleSizeChange(val) {
      this.size = val;
      // console.log(`每页 ${val} 条`+this.size)
      // console.log('gengxin')
      if (this.languageSelect === "zh") {
        // this.getartical();
      } else {
        // this.getarticalEn();
      }
    },
    handleCurrentChange(val) {
      this.page = val;
      this.handleScroll();
      // console.log(`当前页: ${val}`+this.page)
      // if (this.languageSelect === "zh") {
      //   this.getartical();
      // } else {
      //   this.getarticalEn();
      // }
    },
    back() {
      this.change = 0;
      this.trans = false;
    },
  },
};
</script>


<style scoped lang="scss">
.secondary-list {
  margin-top: 2%;
  width: 250px;
  font-size: 12px;
  display: flex; /* 使用Flex布局 */
  flex-wrap: wrap; /* 换行布局 */
  align-items: center; /* 垂直居中 */
}
.secondary-list1 {
  margin-top: 5%;
  width: 250px;
  font-size: 12px;
}
.secondary-item {
  margin-right: 10px; /* 可选：添加水平间距 */
}
.limitTitLe {
  margin-left: 35px;
  font-size: 18px;
  width: 65%;
  color: #333333;
  position: absolute;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.limitTitLe:hover {
  color: var(--main-color);
}

.pagination-container {
  position: sticky;
  margin-top: auto;
  bottom: 0;
  width: 99%;
  text-align: center;
  background-color: #fff;
  padding: 10px;
}

.buttonStyle {
  font-weight: bold;
  font-size: 20px;

  color: black;
  width: 150px;
  height: 55px;
  border-right: #2c3e50;
  border: none;
  border-radius: 5px;
  margin-top: 5px;
  box-shadow: 4px 2px var(--main-color);
}

li {
  list-style-type: none;
}

.news {
  margin-top: -40px;
  min-height: 80vh;
  height: auto;
  overflow-x: hidden;
  background-color: white;
  ::v-deep .el-tabs__item {
    width: 260px;
    border: none;
    font-size: 16px;
    text-align: center;
  }
  ::v-deep .el-tabs__nav-wrap.is-scrollable {
    padding: 0 0px;
    box-sizing: border-box;
  }
  ::v-deep .el-tabs__header .el-tabs__nav {
    border: 0px solid #e4e7ed;
    background-color: white;
    border-bottom: none;
    border-radius: 4px 4px 0 0;
    box-sizing: border-box;
  }
  ::v-deep .el-tabs__item.is-active {
    border-bottom: 2px solid var(--main-color);
    color: var(--main-color);
  }
}

.secondery {
  width: 1280px;
  height: 100%;
  //border: black 1px solid;
}
.list {
  min-height: 45vh;
  height: auto; /* 高度自动增加以适应内容 */
  padding-top: 5px;
  background-color: white;
  margin-left: 2%;
  width: 100%;
  //border: black 1px solid;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

.talk_sub {
  font-size: 5px;
  width: 38%;
  margin-top: 15vh;
  height: 30vh;
  border-radius: 10px;
  background-image: url("../../assets/none.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.talk_word {
  display: flex;
  min-height: 40px;
  white-space: pre-wrap; /* 使文本根据内容自动换行 */
  outline: none;
  width: 60%;
  margin-left: 17.5%;
  max-height: 40px;
  padding: 0px;
  float: left;
  text-indent: 10px;
  border-radius: 8px;
  border: rgb(84, 82, 82) 1px solid;
  background-color: white; /* 半透明白色，alpha值为0.5 */
}

.cascader {
  width: 20%;
  border-color: white !important;
}

::v-deep .seconderyStyle {
  text-align: center;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
  display: inline-block;
  color: black;
  width: 150px !important;
  line-height: 55px !important;
  border-right: #2c3e50;
  border: none;
  border-radius: 5px;
  margin-top: 5px;
  box-shadow: -4px 4px 4px 0 var(--main-color);
}
::v-deep .seconderyStyle1 {
  text-align: left;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  padding-left: 5px;
  display: inline-block;
  color: black;
  width: 125px !important;
  line-height: 45px !important;
  border-right: #2c3e50;
  border: none;
  border-radius: 5px;
  margin-top: 5px;
  box-shadow: -4px 4px 4px 0 var(--main-color);
}
::v-deep .seconderyStyle2 {
  text-align: center;
  align-items: center;
  display: inline-block;
  color: white;
  margin-bottom: 5%;
  background-color: var(--main-color);
  width: 75px !important;
  line-height: 35px !important;
  border-right: #2c3e50;
  border: none;
  cursor: pointer; /* 鼠标变成手型 */
  transition: background-color 0.2s; /* 添加背景颜色的过渡效果 */
  border-radius: 5px;
}
::v-deep .seconderyStyle3 {
  text-align: left;
  align-items: center;
  display: inline-block;
  color: white;
  margin-bottom: 5%;
  padding-left: 5px;
  background-color: var(--main-color);
  width: 165px !important;
  line-height: 35px !important;
  border-right: #2c3e50;
  border: none;
  cursor: pointer; /* 鼠标变成手型 */
  transition: background-color 0.2s; /* 添加背景颜色的过渡效果 */
  border-radius: 5px;
}
.secondery :active {
  color: var(--main-color);
}

.talkcontent {
  min-height: 22px;
  white-space: pre-wrap; /* 使文本根据内容自动换行 */
  outline: none;
  width: 40%;
  max-height: 22px;
  padding: 3px;
  float: left;
  text-indent: 10px;
  border-radius: 8px;
  overflow: auto;
  margin-left: 2%;
  margin-top: 0.5%;
  //border: black solid 1px; background-color: white; /* 半透明白色，alpha值为0.5 */
}

::v-deep .el-cascader .el-input__inner:hover {
  border: none;
}
.limitTitLe1 {
  margin-left: 25px;
  font-size: 16px;
  color: #333333;
  position: absolute;
  width: 60%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.limitTitLe1:hover {
  color: var(--main-color);
}
.limitContent1 {
  display: flex;
  justify-content: space-between;
  margin: 1.5% 3% 0 0;
  width: 850px; /* 调整为你需要的宽度 */
  height: 60px;
  padding-right: 60px;
  font-size: 14px;
  color: #666666;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  line-height: 2;

  p {
    text-align: left;
  }
}
.limitContent1:hover {
  color: var(--main-color);
}
.logo {
  background-image: url("../../assets/artical.png");
  background-size: contain;
  background-position: center;
  background-color: transparent;
  background-repeat: no-repeat;
}
.logo:hover {
  transform: scale(1.1); /* 设置悬停时的放大比例，可以根据需要调整 */
  transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
}

.backlogo {
  background-image: url("../../assets/back.png");
  width: 50px;
  height: 50px;
  margin: 1.5% 2% 0 3%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
}

.collect {
  border: none;
  background-image: url("../../assets/collect.png");
  width: 25px;
  height: 25px;
  margin: 1% 5% 0 3%;
  text-align: right;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.collectReally {
  background-image: url("../../assets/collectReally.png");
  width: 25px;
  height: 25px;
  margin: 1% 5% 0 3%;
  text-align: right;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
}

.translateThis {
  border: none;
  background-image: url("../../assets/trans.png");
  width: 28px;
  height: 28px;
  margin: 1% 5% 0 3%;
  text-align: right;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.translateThisReally {
  background-image: url("../../assets/trans.png");
  width: 28px;
  height: 28px;
  margin: 1% 5% 0 3%;
  text-align: right;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
}

.artical_height {
  height: 90vh;
}
.artical {
  width: 80%;
  border: none;
  margin: 0 auto;
  border-radius: 0px;
  border-bottom: rgba(44, 62, 80, 0.66) 1px dashed;
  height: 130px;

  //box-shadow:  0 0 0.1em 0 rgba(240, 76, 76, 0.5);
}
.artical1 {
  width: 80%;
  margin: 0 auto;
  border: none;
  border-radius: 0px;
  border-bottom: rgba(44, 62, 80, 0.66) 1px dashed;
  height: 115px;
  //box-shadow:  0 0 0.1em 0 rgba(240, 76, 76, 0.5);
}

.detailContent {
  margin-left: 7.5%;
  width: 85%;
  min-height: 94vh;
  height: auto;
}

.custom-cascader .el-cascader-input input {
  border: none !important;
}

.custom-cascader .el-cascader-menu {
  border: 1px solid #fff !important; /* 设置下拉菜单边框为白色 */
}
.limitContent {
  margin: 1.5% 3% 0 150px;
  width: 83%; /* 调整为你需要的宽度 */
  height: 60px;
  font-size: 14px;
  color: #666666;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 2;
  text-align: justify;
  p {
    text-align: left;
  }
}
.limitContent:hover {
  color: var(--main-color);
}
.limit {
  text-align: left;
  color: #666666;
  line-height: 2;
  font-size: 14px;
  margin: 15px 5% 0 1%;
  width: 95.5%; /* 调整为你需要的宽度 */
  height: 55px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word; /* 允许在单词内换行 */

  p {
    text-align: left;
    overflow: hidden;
    word-wrap: break-word; /* 允许在单词内换行 */
    text-overflow: ellipsis;
  }
}
.limit:hover {
  color: var(--main-color);
}
.limitImg {
  margin: 1.5% 2% 0 0.5%; /* 外边距 */
  width: 120px; /* 图片宽度 */
  height: 80px; /* 图片高度 */
  position: absolute;
  border-radius: 5px; /* 边框圆角 */
  box-shadow: 0 2px 2px 2px rgba(102, 102, 102, 0.8); /* 阴影效果 */
  word-wrap: break-word; /* 超出部分换行 */
  overflow: hidden; /* 隐藏溢出部分 */
  text-overflow: ellipsis; /* 溢出时显示省略号 */
}
.limitImg:hover {
  transform: scale(1.1); /* 设置悬停时的放大比例，可以根据需要调整 */
  transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
}

.detail {
  margin: 0.5% 5% 0% 3%;
  width: 95%; /* 调整为你需要的宽度 */
  height: auto;
  word-wrap: break-word;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.detail1 {
  margin: 0.5% 5% 0% 3%;
  width: 95%; /* 调整为你需要的宽度 */
  height: 90%;
  word-wrap: break-word;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.transborder {
  border-radius: 5px;
  border-color: #2c3e50;
  box-shadow: 1px 1px 1px 1px #2c3e50;
  width: 55%;
  margin-right: 1%;
  min-height: 85vh;
  height: auto;
  overflow: auto;
  overflow-x: hidden;
}

.tag {
  float: right;
  display: flex;
  margin-right: 2%;
  color: gray;
  font-size: 14px;
  border-radius: 5px;
}
.scanImg {
  display: inline-block;
  background-image: url("../../assets/scan.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
}
.scan:hover {
  color: var(--main-color);

  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
}
.scan {
  background-size: contain;
  width: 90px;
  height: 20px;
  font-size: 14px;
  align-items: center;
  margin-left: 0.5%;
  display: flex;
}
.agreeImgDeny {
  display: inline-block;
  background-image: url("../../assets/agreeReally.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
}
.agreeImg {
  display: inline-block;
  background-image: url("../../assets/agree.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
}
.agree:hover {
  color: var(--main-color);
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
}
.agree {
  background-size: contain;
  width: 90px;
  align-items: center;
  font-size: 14px;
  height: 20px;
  margin-left: 0.5%;
  display: flex;
}

.news1 {
  position: absolute;
  margin-top: -40px;
  width: 100%;
  height: 90vh;
  overflow-x: hidden;
  background-color: white;

  //  .secondary-list {
  //    width:350px;
  //    font-size: 6px;
  //    display: flex;
  //  }
  //  .secondary-list1 {
  //
  //    width: 120px;
  //    font-size: 6px;
  //  }
  //  ::v-deep .seconderyStyle {
  //    text-align: center;
  //    align-items: center;
  //    font-weight: bold;
  //    font-size: 8px;
  //    display: inline-block;
  //    color: black;
  //    width: 65px !important;
  //    line-height: 20px !important;
  //    border-right: #2c3e50;
  //    border: none;
  //    border-radius: 5px;
  //    box-shadow: -4px 4px 4px 0 var(--main-color);
  //
  //  }
  //
  ::v-deep .el-tabs__item {
    padding: 0 11px !important;
    text-align: center !important;
    height: 30px;
  }
  ::v-deep .el-tabs__item.is-active {
    color: var(--main-color);
  }

  //;
  //  ::v-deep .seconderyStyle1 {
  //    text-align: left;
  //    align-items: center;
  //    font-weight: bold;
  //    font-size: 6px;
  //    padding-left: 2px ;
  //    display: inline-block;
  //    color: black;
  //    width: 35px !important;
  //    line-height: 17px !important;
  //    border-right: #2c3e50;
  //    border: none;
  //    border-radius: 5px;
  //    margin-top: 5px;
  //    box-shadow: -4px 4px 4px 0 var(--main-color);
  //  }
  //  ::v-deep .seconderyStyle2 {
  //    text-align: center;
  //    align-items: center;
  //    display: inline-block;
  //    color: white;
  //    margin-bottom: 5%;
  //    background-color: var(--main-color);
  //    width:75px !important;
  //    line-height: 17px !important;
  //    border-right: #2c3e50;
  //    border: none;
  //    cursor: pointer; /* 鼠标变成手型 */
  //    transition: background-color 0.2s; /* 添加背景颜色的过渡效果 */
  //    border-radius: 5px;
  //
  //  }
  //  ::v-deep .seconderyStyle3 {
  //    text-align: left;
  //    align-items: center;
  //    display: inline-block;
  //    color: white;
  //    margin-bottom: 5%;
  //    padding-left: 5px;
  //    background-color: var(--main-color);
  //    width:55px !important;
  //    line-height: 35px !important;
  //    border-right: #2c3e50;
  //    border: none;
  //    cursor: pointer; /* 鼠标变成手型 */
  //    transition: background-color 0.2s; /* 添加背景颜色的过渡效果 */
  //    border-radius: 5px;
  //
  //  }
  //  .secondery :active {
  //    color: var(--main-color)
  //  }
  .noData,
  .notLogin {
    text-align: center;
    color: #666666;
    font-size: 14px;
    display: flex;
    align-content: center;
    flex-direction: column;
  }
  .noData img,
  .notLogin img {
    width: 100%;
    margin: 0 auto;
  }
  /* 修改 .pagination-container 的样式 */
  .pagination-container {
    position: sticky;
    margin-top: auto;
    bottom: 0;
    font-size: 8px;
    text-align: center;
    background-color: #fff;
  }

  .el-pagination {
    width: 90%;
  }

  .secondery {
    display: flex;
    width: 75px;
    height: 23px;
    font-size: 8px;
    color: black;
    border-right: #2c3e50;
    border: none;
    border-radius: 5px;
    margin-top: 5px;
    text-align: left;
    //border: black 1px solid;
  }
  .list {
    margin-top: 50px;
    height: auto; /* 高度自动增加以适应内容 */
    padding-top: 5px;
    background-color: white;
    width: 100%;
    //border: black 1px solid;
  }
  .logo {
    background-image: url("../../assets/artical.png");
    background-size: 18px 22px;
    background-position: center;
    background-color: transparent;
    background-repeat: no-repeat;
  }
  .logo:hover {
    transform: scale(1.1); /* 设置悬停时的放大比例，可以根据需要调整 */
    transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
  }
  .limitTitLe {
    margin-left: 25px;
    font-size: 16px;
    color: #333333;
    position: absolute;
    width: 60%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .limitTitLe:hover {
    color: var(--main-color);
  }
  .limitTitLe1 {
    margin-left: 25px;
    font-size: 16px;
    color: #333333;
    position: absolute;
    width: 60%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .limitTitLe1:hover {
    color: var(--main-color);
  }
  .artical {
    width: 90%;
    border: none;
    border-radius: 0px;
    border-bottom: rgba(44, 62, 80, 0.66) 1px dashed;
    height: 120px;

    //box-shadow:  0 0 0.1em 0 rgba(240, 76, 76, 0.5);
  }
  .artical1 {
    width: 90%;
    border: none;
    border-radius: 0px;
    border-bottom: rgba(44, 62, 80, 0.66) 1px dashed;
    height: 100px;
    //box-shadow:  0 0 0.1em 0 rgba(240, 76, 76, 0.5);
  }
  .buttonStyle1 {
    font-size: 8px;
    color: #333333;
    width: 65px;
    margin: 5px;
    height: 25px;
    padding: 2px;
    border-right: #2c3e50;
    border: none;
    border-radius: 5px;
    box-shadow: 4px 2px var(--main-color);
  }

  .buttonStyle2 {
    font-size: 10px;
    color: var(--main-color);
    width: 65px;
    height: 25px;
    padding: 2px;
    margin: 5px;
    border-right: #2c3e50;
    border: none;
    border-radius: 5px;
    box-shadow: 4px 2px var(--main-color);
  }
  .buttonStyle3 {
    font-size: 8px;
    color: #333333;
    width: 65px;
    padding: 2px;
    height: 25px;
    margin: 3px;
    border-right: #2c3e50;
    border: none;
    border-radius: 5px;
    box-shadow: 4px 2px var(--main-color);
  }

  .buttonStyle4 {
    font-size: 10px;
    color: var(--main-color);
    width: 65px;
    padding: 2px;
    height: 25px;
    margin: 5px;
    border-right: #2c3e50;
    border: none;
    border-radius: 5px;
    box-shadow: 4px 2px var(--main-color);
  }
  .subscribe {
    background-size: 12px 12px; /* 设置背景图片大小 */
    border: none;
    width: 5px;
    margin: 0 0 0 45px;
    background-image: url("../../assets/subscribe.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-color: transparent; /* 这里设置背景颜色为透明 */
  }

  .subscribeDeny {
    background-size: 12px 12px; /* 设置背景图片大小 */
    border: none;
    margin: 0 0 0 45px;
    background-image: url("../../assets/subscribeDeny.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-color: transparent; /* 这里设置背景颜色为透明 */
  }
  .limit {
    text-align: justify;
    color: #666666;
    line-height: 1.5;
    font-size: 14px;
    margin: 5px 5% 0 1%;
    width: 94%; /* 调整为你需要的宽度 */
    height: 45px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .limit:hover {
    color: var(--main-color);
  }
  .tag {
    float: right;
    display: flex;
    margin-right: 1%;
    margin-top: 5px;
    color: gray;
    line-height: 8px;
    font-size: 12px;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
  }
  .tag1 {
    display: flex;
    margin-left: 95%;
    margin-top: 75px;
    color: gray;
    line-height: 8px;
    font-size: 12px;
    border-radius: 5px;
    align-items: center;
    width: 115%;
    justify-content: flex-end; /* 将内容向弹性盒容器的结束边缘对齐（即右对齐）*/
  }
  .scanImg {
    display: inline-block;
    background-image: url("../../assets/scan.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 15px;
    height: 15px;
  }

  .scan:hover {
    color: var(--main-color);

    transform: scale(1.1);
    transition: transform 0.3s ease-in-out;
  }

  .scan {
    justify-content: center;
    background-size: contain;
    width: 100px;
    height: 15px;
    font-size: 14px;
    align-items: center;
    margin-left: 0.5%;
    display: flex;
  }

  .agreeImg {
    display: inline-block;
    background-image: url("../../assets/agree.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 15px;
    height: 15px;
  }

  .agree:hover {
    color: var(--main-color);
    transform: scale(1.1);
    transition: transform 0.3s ease-in-out;
  }

  .agreeImgDeny {
    display: inline-block;
    background-image: url("../../assets/agreeReally.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 15px;
    height: 15px;
  }

  .agree {
    background-size: contain;
    width: 80px;
    height: 10px;
    font-size: 14px;
    align-items: center;
    margin-left: 0.5%;
    display: flex;
  }

  .limitImg {
    margin: 1.5% 2% 0 1%;
    width: 105px; /* 调整为你需要的宽度 */
    height: 80px;
    border-radius: 5px;
    box-shadow: 0 2px 2px 2px rgba(102, 102, 102, 0.8);
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .limitImg:hover {
    transform: scale(1.1); /* 设置悬停时的放大比例，可以根据需要调整 */
    transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
  }

  .limitContent {
    line-height: 1.5;
    font-size: 14px;
    position: absolute;
    margin: 5px 5% 0 120px;
    width: 55%; /* 调整为你需要的宽度 */
    height: 65px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    color: #666666;
    word-wrap: break-word;
    text-overflow: ellipsis;
    text-align: justify;

    p {
      text-align: left;
    }
  }
  .limitContent:hover {
    color: var(--main-color);
  }
  .limitContent1 {
    margin: 1.5% 3% 0 0;
    width: 80%; /* 调整为你需要的宽度 */
    height: 60px;
    padding-right: 10px;
    font-size: 14px;
    color: #666666;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: justify;
    line-height: 2;

    p {
      text-align: left;
    }
  }
  .limitContent1:hover {
    color: var(--main-color);
  }
}
</style>

